<template>
  <div>
    <index-filter :cols="[3,3]">
      <search-filter
        slot="col_0"
        v-model="filters"
        placeholder="Search"
      />
      <active-filter
        slot="col_1"
        v-model="filters"
      />
    </index-filter>

    <v-card>
      <country-table
        ref="tableRef"
        :table-list="tableList"
      />
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { ActiveFilter, IndexFilter, SearchFilter } from '@/components'
import { useTableList } from '@/composables'
import router from '@/router'
import { mdiClose, mdiRefresh } from '@mdi/js'
import CountryTable from '../components/country/CountryTable.vue'
import { countryService } from '../services'

export default {
  components: {
    IndexFilter,
    SearchFilter,
    ActiveFilter,
    CountryTable,
  },

  setup() {
    const filters = ref({
      search: null,
      currency: null,
      active: true,
    })

    const tableList = useTableList(countryService, filters)

    const resetRoute = () => {
      router.replace({ query: { ...filters.value } })
      tableList.paginate()
    }

    const reserFilterField = field => {
      filters.value[field] = undefined
      resetRoute()
    }

    const {
      loading,
      tableRef,
      paginate,
    } = tableList

    return {
      filters,
      reserFilterField,

      loading,
      tableRef,
      paginate,
      tableList,

      icons: {
        mdiRefresh,
        mdiClose,
      },
    }
  },
}
</script>

  <style lang="scss" scoped></style>
